import { URL_BASE } from "../model";
import { decrypted } from "../crypto";
import { CREATE_ACCESS_API } from "../process";
import { token } from "../crypto/token";

const getData = async ({ url, data }: URL_BASE) => {
  const accessToken = token({ uid: new Date().getTime() });
  const parsePath = url.substring(url.indexOf(".net") + 4, url.length);
  const request = {
    host: "report",
    method: "get",
    path: parsePath,
  };

  const {
    data: { data: getResoponseHash },
  } = await CREATE_ACCESS_API(request, accessToken);
  return decrypted(getResoponseHash);
};

const postData = async ({ url, data }: URL_BASE) => {
  const accessToken = token({ uid: new Date().getTime() });
  const parsePath = url.substring(url.indexOf(".net") + 4, url.length);
  const request = {
    host: "report",
    method: "post",
    path: parsePath,
    body: {
      ...data,
    },
  };

  const {
    data: { data: getResoponseHash },
  } = await CREATE_ACCESS_API(request, accessToken);
  return decrypted(getResoponseHash);
};

export { postData as CREATE_DATA, getData as READ_DATA };
