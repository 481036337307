import React, { useState } from "react";

const Home = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  const openSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return {
    openSnackbar,
    snackbarOpen,
    closeSnackbar,
    snackbarSeverity,
    snackbarMessage,
  };
};

export { Home };
