import axios from "axios";
import { SECURITY_URL, LOGIN_QUERY } from "../config";

const readLogin = async (contact_id?: string, ip?: string, key?: string) => {
  const url = `${SECURITY_URL}${LOGIN_QUERY}/${key}`;
  const data = {
    contact_id,
    ip,
  };
  return axios.post(url, data);
};

export { readLogin as READ_LOGIN_API };