import { TokenProp } from "../model";

const BASE_URL = "/";
const ROOT_URL = "/:id";
const SUCCESS = "/success"

const getRouteRoot = ({ token }: TokenProp) => {
  const parseRoot = ROOT_URL.replace(":id", token);
  const route = `${parseRoot}`;
  return route;
};

const ROUTES = {
  BASE_URL,
  SUCCESS
};

export { ROUTES, getRouteRoot };
