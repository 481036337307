import { HOST_ROUTES } from "../config";
import { decryptedAlpha } from "../crypto";

const readCheckWebView = (id: string) => {
  const { SECURITY } = HOST_ROUTES;
  const request = {
    host: SECURITY.host,
    path: `${SECURITY.paths.web_view}/${decryptedAlpha(id)}`,
    method: "get",
  };

  return request;
};

export { readCheckWebView as READ_CHECK_WEBVIEW_API };
