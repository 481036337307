import React from "react";
import { AiFillLock } from "react-icons/ai";
import "../../styles/components/loader/index.css";

const Loader = () => {
  return (
    <div className="app-loader">
      <div className="puntos">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="app-loader-text">
        <p>
          <AiFillLock /> Con la tecnologia de Trazo
        </p>
      </div>
    </div>
  );
};

export default Loader;
