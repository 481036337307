import { createBrowserRouter } from "react-router-dom";
import Payout from "../../sections/payout";
import Success from "../../sections/success";

import { ROUTES, getRouteRoot } from "../config";

const {
  BASE_URL,
  SUCCESS
} = ROUTES;

const token = window.location.pathname.replace("/", "");

const root = getRouteRoot({ token });
export const router = createBrowserRouter(
  [
    {
      path: BASE_URL,
      element: <Payout />,
    },
    {
      path: SUCCESS,
      element: <Success />,
    },
  ],
  { basename: root },
);
