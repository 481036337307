import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

const TotalRejectedModal = ({
  open,
  close,
  total_payouts,
  total_amount,
  name_receiver,
  bank_receiver,
  currency,
  process_id,
  toSuccess,
  isChecked,
  handleSwitchChange,
  main_color_brand,
  secondary_color_brand,
  tx_reject_reason,
  setTx_reject_reason,
  tx_reject_channel, 
  setTx_reject_channel,
  tx_reject_channel_value, 
  setTx_reject_channel_value
}: any) => {
  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {xs: "64vw", md: 500},
          bgcolor: "background.paper",
          borderRadius: 5,
          boxShadow: 24,
          padding: {xs: "4%", md: "0 2% 2% 2%"},
        }}
      >
        <Box
          sx={{
            width: "105%",
            margin: 0,
            padding: 0,
            textAlign: "end",
            display: "flex",
            justifyContent: "end",
          }}>
          <Button
            onClick={close}
            sx={{
              color: "gray",
              minWidth: "0"
            }}
          >
            <CloseIcon fontSize="medium" sx={{ margin: "0" }} />
          </Button>
        </Box>
        <Box
          sx={{
            margin: "1% 0 2% 0",
            width: "96%",
            padding: "2%",
            display: "flex",
            borderRadius: "8px",
            color: " var(--dark-light-info, #006C9C)",
            backgroundColor: "var(--info-8, #00B8D914)",
            border: "1px solid var(--info-16, #00B8D929)",
          }}
        >
          <ErrorIcon
            sx={{
              width: "18px",
              margin: "0.5% 2% 0 0",
            }}
          />
          <Typography
            sx={{
              width: "100%",
              margin: "0",
              fontSize: {xs: "16px", md: "18px"},
              fontWeight: "500",
              fontFamily: "Inter Tight",
              justifyContent: "space-between",
            }}
          >
            Verifica tu información
          </Typography>
        </Box>
        <Typography
          fontWeight={400}
          sx={{
            margin: "4% 0 5% 0",
            fontFamily: "Inter Tight",
            color: "#637381",
            fontSize: {xs: "14px", md: "16px"}
          }}
        >
          Detalles de la dispersión a rechazar:
        </Typography>
        <Box
          sx={{
            margin: "2% 0 4% 0",
            display: "flex",
            flexDirection: {xs: "column", md: "row"}
          }}
        >
          <Box
            sx={{
              width: {xs: "100%", md: "50%"},
            }}
          >
            <Typography
              sx={{
                fontSize: {xs: "12px", md: "14px"},
                fontFamily: "Inter Tight",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              CANTIDAD:
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: "12px", md: "14px"},
                fontFamily: "Inter Tight",
                color: "#637381",
              }}
            >
              {total_payouts}
            </Typography>
          </Box>
          <Box
            sx={{
              width: {xs: "100%", md: "50%"},
              margin: {xs: "4% 0", md: 0}
            }}
          >
            <Typography
              sx={{
                fontSize: {xs: "12px", md: "14px"},
                fontFamily: "Inter Tight",
                fontWeight: "600",
                color: "#637381",
              }}
            >
              MONTO:
            </Typography>
            <Typography
              sx={{
                fontSize: {xs: "12px", md: "14px"},
                fontFamily: "Inter Tight",
                color: "#637381",
              }}
            >
              {total_amount} {currency}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            margin: "2% 0",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: {xs: "12px", md: "14px"},
              fontFamily: "Inter Tight",
              fontWeight: "600",
              color: "#637381",
            }}
          >
            ID DEL PROCESO:
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: "12px", md: "14px"},
              fontFamily: "Inter Tight",
              color: "#637381",
            }}
          >
            {process_id}
          </Typography>
        </Box>

        <Typography
          fontWeight={400}
          sx={{
            marginBottom: "2%",
            marginTop: "4%",
            fontFamily: "Inter Tight",
            fontSize: {xs: "16px", md: "18px"},
          }}
        >
          Indicanos el motivo del rechazo
        </Typography>
        <FormControl
          variant="outlined"
          fullWidth
          sx={{ padding: 0, marginBottom: "2%" }}
        >
          <InputLabel
            htmlFor="outlined-input"
            sx={{
              fontFamily: "Inter Tight",
              "&.Mui-focused": {
                color: "gray",
                fontSize: {xs: "16px", md: "18px"},
              },
            }}
          >
            Motivo
          </InputLabel>
          <OutlinedInput
            id="outlined"
            label="Motivo"
            placeholder="Escribe el motivo aquí"
            value={tx_reject_reason}
            onChange={(e) => setTx_reject_reason(e.target.value)}
            sx={{
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
              borderRadius: "8px",
            }}
          />
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={isChecked}
              onChange={handleSwitchChange}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: main_color_brand, 
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: main_color_brand,
                },
              }}
            />
          }
          label="Enviar notificación de rechazo"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontFamily: "Inter Tight",
              fontSize: {xs: "13px", md: "14px"},
            },
            marginBottom: "2%",
          }}
        />
        <Box
          sx={{
            display: isChecked ? "flex" : "none"
          }}>
          <FormControl
            sx={{
              width: "50%",
            }}
          >
            <InputLabel
              id="demo-simple-select-label"
              sx={{ fontFamily: "Inter Tight" }}
            >
              Canal
            </InputLabel>
            <Select
              sx={{
                borderRadius: "8px",
                marginRight: "8%",
              }}
              label="Canal"
              value={tx_reject_channel}
              onChange={(e) => setTx_reject_channel(e.target.value)}
            >
              <MenuItem value="whatsapp">WhatsApp</MenuItem>
              <MenuItem value="email">Correo</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: "50%",
            }}
          >
            <InputLabel
              htmlFor="outlined-input"
              sx={{
                fontFamily: "Inter Tight",
                "&.Mui-focused": {
                  color: "gray",
                  fontSize: {xs: "16px", md: "18px"},
                },
              }}
            >
              Número
            </InputLabel>
            <OutlinedInput
              id="outlined"
              label="Número"
              placeholder="Escribe el número aquí"
              value={tx_reject_channel_value}
              onChange={(e) => setTx_reject_channel_value(e.target.value)}
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                borderRadius: "8px",
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            marginTop: "1%",
          }}
        > 
          <Button
            variant="contained"
            onClick={close}
            sx={{
              backgroundColor: secondary_color_brand, 
              mt: 2,
              width: {xs: "30%", md: "16%"},
              textTransform: "none",
              fontFamily: "Inter Tight",
              fontSize: {xs: "12px", md: "14px"},
              fontWeight: "400",
              color: main_color_brand,
              borderRadius: "8px",
              height: {xs: "4vh", md: "5vh"},
              marginRight: "3%",
              '&:hover': {
                backgroundColor: "gray",  
                border: "none", 
                color: "white"      
              },
            }}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            onClick={toSuccess}
            sx={{
              backgroundColor: main_color_brand,
              mt: 2,
              width: {xs: "30%", md: "16%"},
              textTransform: "none",
              fontFamily: "Inter Tight",
              fontSize: {xs: "12px", md: "14px"},
              fontWeight: "400",
              borderRadius: "8px",
              height: {xs: "4vh", md: "5vh"},
              '&:hover': {
                backgroundColor: "gray",  
                border: "none", 
                color: "white"      
              },
            }}
          >
            Continuar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TotalRejectedModal;
