import { HOST_ROUTES } from "../config";

const readLogin = (contact_id?: string) => {
  const { SECURITY } = HOST_ROUTES;

  const request = {
    host: SECURITY.host,
    method: "get",
    path: `${SECURITY.paths.login}/${contact_id}`,
  };

  return request;
};

export { readLogin as READ_LOGIN_TOKEN_API };
