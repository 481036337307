import "./App.css";
import { router } from "./router/src";
import { RouterProvider } from "react-router-dom";
import Home from "./sections/home";

function App() {
  const id = window.location.pathname.replace("/", "");

  return (
    <>
     {id ? (
      <RouterProvider router={router} />
     ): (
      <Home />
     )}
      
    </>
  )
}

export default App;
