//PROD BASE
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const SECURITY_URL = process.env.REACT_APP_SECURITY_BASE_URL;
const CHECK_OTP = process.env.REACT_APP_CHECK_OTP;
const ACCESS_QUERY_ = process.env.REACT_APP_ACCESS_QUERY;
const SECURITY_OTP = process.env.REACT_APP_SECURITY_OTP;
const USER_INFO = process.env.REACT_APP_USER_INFO;
const APPROVAL_RECONCILED_LAYOUT =
  process.env.REACT_APP_APPROVAL_RECONCILED_LAYOUT;

const TOKEN_QUERY = "/v1/merchants/token";
const LOGIN_QUERY = process.env.REACT_APP_LOGIN_QUERY;
const USER_CHECK = process.env.REACT_APP_USER_CHECK;
const WEBVIEW_CHECK = process.env.REACT_APP_WEBVIEW_CHECK;
const SUMMARY_QUERY = process.env.REACT_APP_SUMMARY_QUERY;
const LIST_QUERY = process.env.REACT_APP_LIST_QUERY;
const MERCHANT_QUERY = process.env.REACT_APP_MERCHANT_QUERY;
const SUCCESS_INFO = process.env.REACT_APP_SUCCESS_INFO

const ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA =
  process.env.REACT_APP_ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA;
const ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA =
  process.env.REACT_APP_ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA;

const HOST_ROUTES = {
  SECURITY: {
    host: "security",
    paths: {
      login: LOGIN_QUERY,
      check: USER_CHECK,
      token: TOKEN_QUERY,
      web_view: WEBVIEW_CHECK,
    },
  },
  REPORT: {
    host: "report",
    paths: {
      otp: SECURITY_OTP,
      check_otp: CHECK_OTP,
      user_info: USER_INFO,
      approval_reconciled_layout: APPROVAL_RECONCILED_LAYOUT,
    },
  },
};
export {
  API_BASE_URL,
  SECURITY_URL,
  TOKEN_QUERY,
  LOGIN_QUERY,
  USER_CHECK,
  CHECK_OTP,
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA,
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA,
  ACCESS_QUERY_,
  SUMMARY_QUERY,
  LIST_QUERY,
  MERCHANT_QUERY,
  SUCCESS_INFO,
  HOST_ROUTES,
};
