import { API_BASE_URL, SUMMARY_QUERY } from "../config";
import { CREATE_DATA } from "../api-call";
import { decrypted } from "../crypto/";

const readPayoutSummary = ({ id }: { id: string }) => {

  const url = `${API_BASE_URL}${SUMMARY_QUERY}`;
  const data = {
    id: id
  }
  return CREATE_DATA({ url, data });
};

export { readPayoutSummary as READ_PAYOUT_SUMMARY };
