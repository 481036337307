import Crypto from "crypto-js";
import {
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA,
  ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA,
} from "../config";

const encrypt = (data: unknown) =>
  Crypto.AES.encrypt(
    JSON.stringify(data),
    ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA as string
  ).toString();

const decrypted = (data: string) =>
  JSON.parse(
    Crypto.AES.decrypt(
      data,
      ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_OMEGA as string
    ).toString(Crypto.enc.Utf8)
  );

const decryptedAlpha = (data: string) =>
  JSON.parse(
    Crypto.AES.decrypt(
      data,
      ENCRYPT_CRYPTO_SECRET_PRIVATE_KEY_ALPHA as string
    ).toString(Crypto.enc.Utf8)
  );
export { encrypt, decrypted,decryptedAlpha };
