import axios from "axios";
import { SECURITY_URL, ACCESS_QUERY_ } from "../config/index";
import { encrypt } from "../crypto";

const createAccess = async (data: Object, access_token: string) => {
  const url = `${SECURITY_URL}${ACCESS_QUERY_}`;
  const data_hash = { data: encrypt(data) };
  
  const data_headers = {
    headers: {
      "qtz-auth-token": access_token,
    },
  };

  return axios.post(url, data_hash, data_headers);
};

export { createAccess as CREATE_ACCESS_API };