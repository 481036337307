import React from "react";
import { AiFillLock } from "react-icons/ai";
import { MuiOtpInput } from "mui-one-time-password-input";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Modal, Box, Typography, Stack, Button, Link } from "@mui/material";

const OtpModal = ({
  open,
  close,
  otp,
  handleChange,
  handleKeyDown,
  checkOtp,
  disabled,
  handleResend,
  generateAlert,
  main_color_brand,
  phone,
  email,
  user,
  errorMsg
}: any) => {
  return (
    <Modal open={open} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 280,
          bgcolor: "background.paper",
          borderRadius: 5,
          boxShadow: 24,
          p: 4,
        }}
      >
        <AiFillLock
          style={{
            width: "100%",
            height: "8vh",
          }}
        />
        <Typography
          sx={{
            margin: "4% 0 6% 0",
            fontSize: "24px",
            fontWeight: "600",
            fontFamily: "Inter Tight",
            textAlign: "center",
          }}
        >
          Ingresa tu código
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={400}
          sx={{
            marginBottom: "5%",
            fontFamily: "Inter Tight",
            color: "gray",
            textAlign: "center",
          }}
        >
          Hola {user}, hemos enviado un código de verificación de 6 dígitos a tu número de
          Whatsapp {phone} y/o correo electrónico {email} asociado a esta cuenta.
        </Typography>
        {!!errorMsg && (
          <Typography
            fontSize="14px"
            fontWeight={400}
            sx={{
              marginBottom: "5%",
              fontFamily: "Inter Tight",
              color: "red",
              textAlign: "center",
              }}
          >
            {errorMsg}
          </Typography>
        )}
        <Typography
          fontSize="14px"
          fontWeight={400}
          sx={{
            marginBottom: "5%",
            fontFamily: "Inter Tight",
            color: "gray",
            textAlign: "center",
          }}
        >
          Por favor ingresalo a continuación
        </Typography>
        <MuiOtpInput
          length={6}
          value={otp}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          sx={{
            borderRadius: "8px",
            gap: 1,
            my: 3,
            input: {
              color: "#000",
              borderRadius: "8px",
              padding: 1,
            },
          }}
        />
        <Button
          fullWidth
          onClick={checkOtp}
          variant="contained"
          sx={{
            backgroundColor: main_color_brand,
            textTransform: "none",
            fontFamily: "Inter Tight",
            fontSize: "14px",
            fontWeight: "400",
            borderRadius: "8px",
            '&:hover': {
              backgroundColor: "gray",  
              border: "none", 
              color: "white"      
            },
          }}
        >
          Verificar
        </Button>
        <Stack
          direction="row"
          spacing={0.5}
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "10%" }}
        >
          <Typography variant="body2" color="black">
            ¿No ha llegado?{" "}
            <Link
              sx={{
                cursor: disabled ? "not-allowed" : "pointer",
                pointerEvents: disabled ? "none" : "auto",
                color: disabled ? "gray" : "primary",
              }}
              underline="always"
              color="primary"
              onClick={handleResend}
            >
              Reenviar código
            </Link>
          </Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "4%"
          }}
        >
          <Button
            onClick={generateAlert}
            variant="text"
            sx={{ 
              color: "gray", 
              fontSize: "14px", 
              fontWeight: "500",
              textTransform: "none",
              fontFamily: "Inter Tight",
              margin: "0",
          }}
            startIcon={<ReportProblemIcon />}
          >
            Necesito ayuda
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OtpModal;
