import { HOST_ROUTES } from "../config";

const readOTP = (contact_id?: string) => {
  const { REPORT } = HOST_ROUTES;

  const request = {
    host: "report",
    method: "get",
    path: `${REPORT.paths.otp}/${contact_id}`,
  };

  return request;
};

export { readOTP as READ_OTP_API };