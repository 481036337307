import { encrypt } from "../actions";
const token = (data?: Object) => {
  let expires = new Date();
  expires.setSeconds(expires.getSeconds() + 3);

  return encrypt({
    ...data,
    expires,
    timeStamp: new Date().toISOString(),
  });
};

export { token };
