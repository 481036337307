import { HOST_ROUTES } from "../config";

const createCheckOTP = (data?: Object) => {
  const { REPORT } = HOST_ROUTES;
  const request = {
    host: "report",
    method: "post",
    path: `${REPORT.paths.check_otp}`,
    body: { ...data },
  };

  return request;
};

export { createCheckOTP as CREATE_CHECK_OTP_API };