import { SECURITY_URL, USER_CHECK } from "../config";

const readUserExist = (contact_id: string) => {
  const request = {
    host: "security",
    path: USER_CHECK,
    method: "post",
    body: {
      contact_id,
    },
  };

  return request;
};

export { readUserExist as READ_USER_EXIST_API };
